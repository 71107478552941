import React, { useEffect, useState } from 'react';
import axiosService from '../../services/axios.service';
import Loader from '../Common/Loader/Loader';
import PropTypes from 'prop-types';
import ArtistsList from './ArtistsList';
import Search from '../Common/Search/Search';
import { getOffsetFromPageQuery } from '../../helpers/pagination.helper';
import QueryParamsHelper from '../../helpers/query-params.helper';
import Pagination from '../Common/Pagination/Pagination';
import hasWindow from '../../constants/has-window.const';
import { artistsLimit } from '../../constants/pagination-limits.const';
import { isNotEmptyArray } from '../../helpers/arrays.helper';
import { useLocation } from '@reach/router';
import { handleBadRequestError } from '../../helpers/error-handler.helper';
import { searchOptions } from '../../constants/search-options.const';
import StickySearchWrapper from '../Common/StickySearchWrapper/StickySearchWrapper';

export const DynamicArtistsList = ({ isPreviewMode = false }) => {
  const path = `/bands`;
  const limit = artistsLimit;
  const apiUrl = `${process.env.API_URL}/v2/artists`;
  const [artists, setArtists] = useState([]);
  const [isArtistsLoaded, setIsArtistsLoaded] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [paramsState, setParamsState] = useState(null);
  const [isQueryError, setIsQueryError] = useState(false);

  if (hasWindow) {
    const deps = isPreviewMode ? [] : [useLocation()];

    useEffect(() => {
      setParamsState(getParamsStateFromUrlQuery());
    }, deps);
  }

  useEffect(() => {
    if (paramsState) {
      loadArtists();
    }
  }, [paramsState]);

  function getParamsStateFromUrlQuery() {
    return {
      offset: getOffsetFromPageQuery(limit),
      query: QueryParamsHelper.getOne('query'),
    }
  }

  function loadArtists() {
    setIsLoader(true);
    setIsQueryError(false);
    getArtists(getParams())
      .then((res) => {
        setArtists(res.data);
        setTotalCount(res.meta.totalCount);
        setIsArtistsLoaded(true);
      })
      .catch(error => handleBadRequestError(error, onFieldError))
      .finally(() => setIsLoader(false));
  }

  async function getArtists(params) {
    return await axiosService.get(apiUrl, { params }).then((res) => res.data);
  }

  function getParams() {
    let params = {
      limit,
      offset: paramsState ? paramsState.offset : 0,
    }

    if (paramsState && paramsState.query && paramsState.query.length > 0) {
      params = {query: paramsState.query, ...params}
    }

    return params;
  }

  function getQuery() {
    return paramsState ? paramsState.query : '';
  }

  function onFieldError(field) {
    if (field && field.property && field.property === 'query') {
      setIsQueryError(true);
    }
  }

  return (
    <div className={`container`}>
      <StickySearchWrapper>
        <Search
          isPreviewMode={isPreviewMode}
          inputQuery={getQuery()}
          hasError={isQueryError}
          defaultSearchOption={searchOptions[3]}
          className={`mb-2`}
        />
      </StickySearchWrapper>
      {isLoader && (<Loader hasBackground={true} isFixed={true}/>)}
      <div className="row">
        <div className="col col-12 pt-2">
          {
            isArtistsLoaded && (
              <div className={!isNotEmptyArray(artists) ? 'd-none' : ''}>
                <ArtistsList artists={artists} className={`px-0 px-lg-3`}/>
                <div className="mt-auto">
                  <Pagination
                    totalCount={totalCount}
                    offset={paramsState ? paramsState.offset : 0}
                    limit={limit}
                    path={path}
                    isPreviewMode={isPreviewMode}
                  />
                </div>
              </div>
            )
          }
          {
            !isNotEmptyArray(artists) && isArtistsLoaded && (
              <p>We're sorry, but no results were found for <strong>"{getQuery()}"</strong>.</p>
            )
          }
        </div>
      </div>
    </div>
  );
}

DynamicArtistsList.propTypes = {
  isPreviewMode: PropTypes.bool,
};

export default DynamicArtistsList;
