import React from 'react'
import ArtistCard from './ArtistCard/ArtistCard';
import PropTypes from 'prop-types';

const ArtistsList = ({ artists, className }) => {
  return (
    <ul className={`list-unstyled artists-list mb-6 ${className}`}>
      {
        artists && artists.length > 0 && artists.map((artist, key) => (
          (
            <li className="artists-list-item mb-3" key={key}>
              <ArtistCard artist={artist}/>
            </li>
          )
        ))
      }
    </ul>
  )
};

ArtistsList.propTypes = {
  artists: PropTypes.array,
};


export default ArtistsList;
