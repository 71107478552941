import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import AnimateHeight from 'react-animate-height';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import './artist-card.scss'
import PreviewCompatibleImage from '../../Common/PreviewCompatibleImage';
import { getHeroImageSmallResPath } from '../../../helpers/images.helper';
import axiosService from '../../../services/axios.service';
import { isNotEmptyArray } from '../../../helpers/arrays.helper';
import Loader from '../../Common/Loader/Loader';
import LessonCard from '../../Lessons/lesson-card/LessonCard';
import { getArtistInitials } from '../../../helpers/artists.helper';

const ArtistCard = ({ artist }) => {
  const limit = 3;
  const lessonsPath = '/lessons'
  const apiUrl = `${process.env.API_URL}/v2/lessons`;
  const [isOpen, setIsOpen] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [lessons, setLessons] = useState([]);

  useEffect(() => {
    setIsOpen(false)
    setLessons([]);
  }, [artist])

  async function getArtistLessons(params) {
    return await axiosService.get(apiUrl, { params }).then((res) => res.data);
  }

  function getParams() {
    if (isValidArtist()) {
      return  {
        limit,
        offset: 0,
        [artist.attributesKey]: String(artist.id),
      }
    }
  }

  function getActiveClass() {
    return isOpen ? 'active' : '';
  }

  function isValidArtist() {
    return artist && artist.hasOwnProperty('id')
      && artist.hasOwnProperty('title')
      && artist.hasOwnProperty('attributesKey')
      && typeof artist.id === 'number'
      && typeof artist.title === 'string'
      && typeof artist.attributesKey === 'string';
  }

  function getViewAllPath() {
    if (isValidArtist()) {
      return `${lessonsPath}/?${artist.attributesKey}=${artist.id}`
    }
  }

  async function toggleArtistCardState() {
    if (!isOpen && !isNotEmptyArray(lessons)) {
      setIsLoader(true);
      const lessons = await getArtistLessons(getParams()).then((res) => res.data);
      setLessons(lessons);
      setIsLoader(false);
    }

    setIsOpen(!isOpen);
  }

  return isValidArtist() && (
    <section className={`artist-card ${getActiveClass()} position-relative`}>
      { isLoader && ( <Loader hasBackground={true}/> ) }
      <div className="artist-card-header" onClick={toggleArtistCardState}>
        <div className="thumbnail mr-3">
          <div className="thumbnail-container">
            <div className="thumbnail-wrapper">
              {
                artist && artist.thumbnail && (
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: getHeroImageSmallResPath(artist.thumbnail),
                      alt: artist.title,
                    }}
                  />
                )
              }
              {
                !artist || !artist.thumbnail && (<p className={`alt-text p-2 text-center`}>{getArtistInitials(artist.title)}</p>)
              }
            </div>
          </div>
        </div>
        <h4 className="artist-card-title mb-0 flex-grow-1">{artist.title}</h4>
        <span className="uil uil-angle-down"/>
      </div>
      <AnimateHeight duration={ 300 } height={ isOpen ? 'auto' : 0 }>
        <div className={`mx-2 pt-2 px-2 pb-3 artist-card-content`}>
          {
            isNotEmptyArray(lessons) && (
              <div className={`pl-0 pl-sm-9`}>
                <ul className="mb-4 mt-3 p-0 artist-lessons-list list-unstyled">
                  {
                    lessons.map((lesson, key) => {
                      const path = `${lessonsPath}/${lesson.id}-${_.kebabCase(lesson.title)}`
                      return (
                        <li key={key} className="mb-3 pb-2">
                          <Link to={path} className="link-primary">
                            <LessonCard lesson={lesson} isSmallView={true}/>
                          </Link>
                        </li>
                      )
                    })
                  }
                </ul>
                <Link to={getViewAllPath()} className="small">View All</Link>
              </div>
            )
          }
          {
            !isNotEmptyArray(lessons) && (
              <p className={`mb-0 mt-2`}>We're sorry, but there are no lessons that match the artist.</p>
            )
          }
        </div>
      </AnimateHeight>
    </section>
  )
}

ArtistCard.propTypes = {
  artist: PropTypes.object,
};

export default ArtistCard;
